// Translated
// Migrated
<template lang="pug">
VueMultiselect.country-select.phone-country-select(
  :id="id",
  ref="ms",
  v-model="countryCode",
  label="value",
  :track-by="trackBy",
  deselect-label=" ",
  group-label="type",
  group-values="list",
  :group-select="false",
  :allow-empty="false",
  :show-labels="false",
  :options="countries",
  :disabled="disabled",
  :placeholder="$t('formSelectCountryPlaceholder')",
  :searchable="true",
  @update:model-value="changeCode",
  @select="$emit('select', $event)"
)
  template(#singleLabel="props", )
    span.option__desc(data-i18n="formSelectCountryPlaceholder")
      span.option__title
        img.svg-icon.mr-2(
          :src="`/images/countries/${props.option.key.toLowerCase()}.svg`"
        )
        | +{{ props.option.dial_code }}
  template(#option="props", )
    .group-label.country-label(v-if="props.option.$isLabel")
    .group-value(v-else)
      span
        img.svg-icon.mr-2(
          loading="lazy",
          :src="`/images/countries/${props.option.key.toLowerCase()}.svg`"
        )
        | +{{ props.option.dial_code }} {{ props.option.value }}
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    value: {
      type: [Number, String],
      default: null
    },

    dialCode: {
      type: Boolean,
      default: false
    },

    trackByValue: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: null
    }
  },

  emits: ['input', 'select'],

  data () {
    return {
      countryCode: {}
    }
  },

  created () {
    this.countryCode = this.getDefaultCountry
  },

  computed: {
    ...mapState(useLocaleStore, {
      commonCountries: 'commonCountries',
      countriesState: 'countries',
      getDefaultCountry: 'getDefaultCountry'
    }),

    trackBy () {
      return this.dialCode ? 'dial_code' :
        this.trackByValue ? 'value' : 'key'
    },

    countries () {
      return [
        {
          type: 'common',
          list: this.commonCountries
        },
        {
          type: 'all',
          list: this.countriesState.filter((c) => {
            return c.dial_code && !this.commonCountries.find(cc => c.key === cc.key)
          })
        }
      ]
    }
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    value (v) {
      this.countryCode = this.countriesState.find(c => c[this.trackBy] === this.value)
    }
  },

  mounted () {
    if (!this.value) {
      return this.changeCode(this.countryCode)
    }

    this.countryCode = this.countriesState.find(c => c[this.trackBy] === this.value)
  },

  beforeUnmount () {
    this.$refs.ms.deactivate()
  },

  methods: {
    changeCode (value) {
      this.countryCode = value

      this.$emit('input', value)
    }
  }
})
</script>
